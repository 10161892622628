import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PrivateRouteAdmin from "./PrivateRouteAdmin";
import dm from "./theme/enc";

import Login from "./pages/user/Login";
import Register from "./pages/user/Register";
import Home from "./pages/user/Home";
import CSKH from "./pages/user/Cskh";
import Notification from "./pages/user/Notification";

// Game
import Keno1 from "./pages/game/Keno/Keno1";
import Keno3 from "./pages/game/Keno/Keno3";
import Keno5 from "./pages/game/Keno/Keno5";
import Xucxac3 from "./pages/game/Xucxac/Xucxac3";

// User
import Profile from "./pages/user/Profile";
import HistoryAll from "./pages/user/HistoryAll";
import HistoryBet from "./pages/user/HistoryBet";
import HistoryAdd from "./pages/user/HistoryAdd";
import HistoryWithDraw from "./pages/user/HistoryWithDraw";
import AddMoney from "./pages/user/AddMoney";
import WithDraw from "./pages/user/WithDraw";
import AddBank from "./pages/user/AddBank";
import EditBank from "./pages/user/EditBank";
import ResetPassword from "./pages/user/ResetPassword";
// Admin
import HistoryBetAll from "./pages/admin/HistoryBetAll";
import PrivateCustomer from "./PrivateCustomer";
import DashboardCustomer from "./pages/admin/DashboardCustomer";
import UsersByEmployeeNV from "./pages/admin/UsersByEmployeeNV";
import BankAdmin from "./pages/admin/BankAdmin";
import Set1 from "./pages/admin/Set1";
import Set5 from "./pages/admin/Set5";
import Set3 from "./pages/admin/Set3";
import Dashboard from "./pages/admin/Dashboard";
import Request from "./pages/admin/Request";
import Users from "./pages/admin/Users";
import Add from "./pages/admin/Add";
import UserProfile from "./pages/admin/UserProfile";
import ThongBao from "./pages/admin/ThongBao";
import Setting from "./pages/admin/Setting";
import Employee from "./pages/admin/Employee";
import AllEmployee from "./pages/admin/AllEmployee";
import UsersByEmployee from "./pages/admin/UsersByEmployee";
import SetXS3p from "./pages/admin/SetXS3p";
import Xucxac5 from "./pages/game/Xucxac/Xucxac5";
import SetXS5p from "./pages/admin/SetXS5p";

function App() {
	const encodedDo = 'dmVuaWNlNj' + dm();
	const currentDo = window.location.hostname;
    const encodedCurrentDo = btoa(currentDo);
    const isAllowedDo = encodedCurrentDo === encodedDo;
	return (
		<div className="App">
			{isAllowedDo ? (
			<Router>
				<Routes>
					<Route element={<Login />} path="/login" />
					<Route element={<Register />} path="/register" />
					<Route element={<Home />} path="/" />
					<Route element={<CSKH />} path="/cskh" />
					<Route element={<Notification />} path="/notification" />

					<Route path="/" element={<PrivateRoute />}>
						{/* Game */}
						<Route element={<Keno5 />} path="/keno5p" />
						<Route element={<Keno3 />} path="/keno3p" />
						<Route element={<Keno1 />} path="/keno1p" />
						<Route element={<Xucxac3 />} path="/xucxac" />
						<Route element={<Xucxac5 />} path="/xucxac5" />	

						{/* User */}
						<Route element={<Profile />} path="/profile" />
						<Route element={<HistoryAll />} path="/history" />
						<Route element={<HistoryBet />} path="/historyplay" />
						<Route element={<HistoryAdd />} path="/historyadd" />
						<Route element={<HistoryWithDraw />} path="/historyget" />
						<Route element={<AddMoney />} path="/addmoney" />
						<Route element={<WithDraw />} path="/withdraw" />
						<Route element={<AddBank />} path="/addbank" />
						<Route element={<EditBank />} path="/bank/:id" />
						<Route element={<ResetPassword />} path="/password" />

					</Route>
					<Route path="/admin" element={<PrivateRouteAdmin />}>
						{/* Admin */}
						<Route element={<Dashboard />} path="/admin" exact />
						<Route element={<HistoryBetAll />} path="/admin/history" exact />
						<Route element={<Request />} path="/admin/request" exact />
						<Route element={<Add />} path="/admin/add" exact />

						<Route element={<Set1 />} path="/admin/set1" exact />
						<Route element={<Set3 />} path="/admin/set3" exact />
						<Route element={<SetXS3p/>} path="/admin/setxs3" exact />
						<Route element={<SetXS5p/>} path="/admin/setxs5" exact />
						<Route element={<Set5 />} path="/admin/set5" exact />
						<Route element={<Users />} path="/admin/users" exact />
						<Route element={<UserProfile />} path="/admin/user/:id" />

						<Route element={<AllEmployee />} path="/admin/allNV" exact />
						<Route element={<Employee />} path="/admin/employee" exact />
						<Route element={<UsersByEmployee />} path="/admin/employee/:id" />

						<Route element={<Setting />} path="/admin/setting" exact />
						<Route element={<BankAdmin />} path="/admin/bank" exact />
						<Route element={<ThongBao />} path="/admin/notification" />
					</Route>
					<Route path="/customer" element={<PrivateCustomer />}>
						<Route element={<DashboardCustomer />} path="/customer" exact />
						<Route element={<UsersByEmployeeNV />} path="/customer/usercustomer" exact />
					</Route>
				</Routes>
			</Router>
			): null}
		</div>
	);
}

export default App;
